import React, { useRef, useEffect, useState } from "react";
import { IconButton, ArrowUpIcon, Box } from "native-base";

import yt from "../assets/yt.png";
import fb from "../assets/fb.png";
import twitter from "../assets/twitter.png";
import insta from "../assets/instagram.png";
import substack from "../assets/substack.png";
import linkedin from "../assets/linkedin.png";
import discord from "../assets/discord_small.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const halfPageHeight = window.innerHeight / 3;
      setShowScrollButton(scrollPosition > halfPageHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="div-para">
        <div className="div-social-media">
          <a href="http://youtube.com/@piattoapp">
            <img loading="lazy" srcSet={yt} class="pointer" />
          </a>
          <a href="https://www.instagram.com/piattoit">
            <img loading="lazy" srcSet={insta} class="pointer" />
          </a>
          <a href="https://www.facebook.com/piattoAUS">
            <img loading="lazy" srcSet={fb} class="pointer" />
          </a>
          <a href="https://twitter.com/@PiattoNow">
            <img loading="lazy" srcSet={twitter} class="pointer" />
          </a>
          <a href="https://piatto.substack.com/">
            <img loading="lazy" srcSet={substack} class="pointer" />
          </a>
          <a href="https://www.linkedin.com/company/piattoaus/">
            <img loading="lazy" srcSet={linkedin} class="pointer" />
          </a>
          <a href="https://discord.com/invite/6dcdt2d24x">
            <img loading="lazy" srcSet={discord} class="pointer" />
          </a>
        </div>
        <div className="footer span-montserrat">
          <span>&copy; 2024 Piatto. All rights reserved.</span>
          <br />
          <span>
            <a
              href="https://piatto.com.au/Piatto-App_Privacy_Policy_5Mar2022.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </span>

          <span>
            <a
              href="https://piatto.com.au/Piatto-Complaint_Policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Complaints Policy
            </a>
          </span>
          <span>
            <a
              href="https://piatto.com.au/Piatto-Data_Breach_Policy_5Mar2022.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Data Break Policy
            </a>
          </span>
          <div>
            <span>
              <a
                href="https://piatto.com.au/Piatto_TermsAndConditions_Venues.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions for Venues
              </a>
            </span>
            <span>
              <a
                href="https://piatto.com.au/Piatto_TermsAndConditions_Users.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions for Users
              </a>
            </span>
          </div>
          {showScrollButton && (
            <Box
              bg="green.50"
              borderRadius="full"
              p={1}
              position="fixed"
              bottom={8}
              right={50}
            >
              <IconButton
                aria-label="Scroll to Top"
                icon={<ArrowUpIcon size="sm" />}
                onPress={scrollToTop}
                variant="unstyled"
              />
            </Box>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .footer span a {
            color: white;
            margin-left: 10px;
          }

          .footer {
            align-items: center;
            padding: 10px 20px;
          }

          .scroll-top-btn {
            background-color: #4caf50;
            color: white;
            border: none;
            padding: 10px 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            margin-right: 20px;
            cursor: pointer;
            border-radius: 5px;
          }

          .scroll-top-btn:hover {
            background-color: #45a049;
          }
          .div-social-media {
            display: flex;
            align-item: center;
            justify-item: center;
            gap: 9px;
          }
          // .div-social-media {
          //   align-self: stretch;
          //   display: flex;
          //   gap: 9px;
          //   margin: auto 0;
          //   padding: 2px 16px 2px 0;
          // }
        `}
      </style>
    </>
  );
};
export default Footer;
