import { Entypo } from "@native-base/icons";
import { Formik } from "formik";
import {
  Button,
  Center,
  Divider,
  FormControl,
  Hidden,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Text,
  VStack,
} from "native-base";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from "../assets/logo.svg";
import GuestLayout from "../components/GuestLayout";
import { SignUpOnWebApp } from "../Redux/actions";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { useMount } from "react-use";
import { useAppContext } from "../context";

function SignUpForm() {
  const [showPass, setShowPass] = React.useState(false);
  const [CshowPass, setCShowPass] = React.useState(false);
  const [ReCAP, setReCAP] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [showLoader, setLoader] = React.useState(true);
  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setLoader(false);
        setState({ run: true, stepIndex: 1 });
      }, 600);
    }
  });
  const validate = (values) => {
    const errors = {};

    if (!values.Name) {
      errors.Name = "Name is Required";
    }
    if (!values.Email) {
      errors.Email = "Email is Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) {
      errors.Email = "Invalid email address";
    }
    if (values.Pass && values.Pass.length <= 5) {
      errors.Pass = "Password should be at least 6 character";
    }
    if (values.CPass.length <= 5) {
      errors.CPass = "Confirm Password is Required";
    }
    if (values.CPass && values.CPass !== values.Pass) {
      errors.Pass = "Passwords don't match";
      errors.CPass = "Passwords don't match";
    }
    if (!values.Pass) {
      errors.Pass = "Password is Required";
    }
    return errors;
  };
  const onSubmit = (data) => {
    dispatch(SignUpOnWebApp(data, history));
  };

  return (
    <VStack
      flex="1"
      px="6"
      py="9"
      _light={{ bg: "white" }}
      _dark={{ bg: "coolGray.800" }}
      justifyContent="space-between"
      space="3"
      borderTopRightRadius={{ base: "2xl", md: "xl" }}
      borderBottomRightRadius={{ base: "0", md: "xl" }}
      borderTopLeftRadius={{ base: "2xl", md: "0" }}
    >
      <VStack space="7">
        <Hidden till="md">
          <Text
            fontSize="3xl"
            fontWeight="bold"
            fontFamily="Inclusive Sans, sans-serif"
          >
            <span id="routeA">Welcome</span>
          </Text>
          <Text
            fontSize="lg"
            fontWeight="normal"
            fontFamily="Inclusive Sans, sans-serif"
          >
            Sign up to continue!
          </Text>
        </Hidden>
        <VStack>
          <VStack space="8">
            <VStack space="4">
              <Formik
                initialValues={{
                  Name: "",
                  Email: "",
                  Pass: "",
                  CPass: "",
                }}
                onSubmit={onSubmit}
                validate={validate}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                }) => (
                  <VStack space={4}>
                    <FormControl isRequired isInvalid={"Name" in errors}>
                      <Input
                        autoComplete="off"
                        py="3"
                        borderRadius="4"
                        labelColor="#9ca3af"
                        fontWeight="semibold"
                        onBlur={handleBlur("Name")}
                        placeholder="Name"
                        onChangeText={handleChange("Name")}
                        value={values.Name}
                        style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                        placeholderTextFontFamily="Inclusive Sans, sans-serif"
                      />
                      <FormControl.ErrorMessage>
                        <span className="inclusive-font-span ">
                          {errors.Name}
                        </span>
                      </FormControl.ErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={"Email" in errors}>
                      <Input
                        autoComplete="off"
                        py="3"
                        borderRadius="4"
                        labelColor="#9ca3af"
                        fontWeight="semibold"
                        onBlur={handleBlur("Email")}
                        placeholder="Email"
                        onChangeText={handleChange("Email")}
                        value={values.Email}
                        style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                        placeholderTextFontFamily="Inclusive Sans, sans-serif"
                      />
                      <FormControl.ErrorMessage>
                        <span className="inclusive-font-span ">
                          {errors.Email}
                        </span>
                      </FormControl.ErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={"Pass" in errors}>
                      <Input
                        py="3"
                        borderRadius="4"
                        type={showPass ? "" : "password"}
                        labelColor="#9ca3af"
                        style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                        placeholderTextFontFamily="Inclusive Sans, sans-serif"
                        InputRightElement={
                          <IconButton
                            mr="1"
                            variant="unstyled"
                            icon={
                              <Icon
                                size="4"
                                color="coolGray.400"
                                as={Entypo}
                                name={showPass ? "eye" : "eye-with-line"}
                              />
                            }
                            onPress={() => {
                              setShowPass(!showPass);
                            }}
                          />
                        }
                        fontWeight="semibold"
                        onBlur={handleBlur("Pass")}
                        placeholder="Password"
                        onChangeText={handleChange("Pass")}
                        value={values.Pass}
                      />
                      <FormControl.ErrorMessage>
                        <span className="inclusive-font-span ">
                          {errors.Pass}
                        </span>
                      </FormControl.ErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={"CPass" in errors}>
                      <Input
                        py="3"
                        borderRadius="4"
                        type={CshowPass ? "" : "password"}
                        labelColor="#9ca3af"
                        style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                        placeholderTextFontFamily="Inclusive Sans, sans-serif"
                        InputRightElement={
                          <IconButton
                            mr="1"
                            variant="unstyled"
                            icon={
                              <Icon
                                size="4"
                                color="coolGray.400"
                                as={Entypo}
                                name={CshowPass ? "eye" : "eye-with-line"}
                              />
                            }
                            onPress={() => {
                              setCShowPass(!CshowPass);
                            }}
                          />
                        }
                        fontWeight="semibold"
                        onBlur={handleBlur("CPass")}
                        placeholder="Confirm Password"
                        onChangeText={handleChange("CPass")}
                        value={values.CPass}
                      />
                      <FormControl.ErrorMessage>
                        <span className="inclusive-font-span ">
                          {errors.Pass}
                        </span>
                      </FormControl.ErrorMessage>
                    </FormControl>
                    <Center>
                      <ReCAPTCHA
                        style={{
                          transform: "scale(0.85)",
                          transformOrigin: "0 0",
                        }}
                        size="normal"
                        sitekey="6LcjByUqAAAAAH-9xv35Jmu6slfjpN4JILI2nr-1"
                        onChange={(value) => setReCAP(value)}
                      />
                    </Center>
                    <span
                      className="inclusive-font-span gray"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      By proceeding, you agree to Piatto's{""} &nbsp;
                      <a
                        href="https://piatto.com.au/Piatto_TermsAndConditions_Venues.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a> &nbsp;
                      for Venues.
                    </span>
                    <Button
                      // isDisabled={ReCAP == "" || ReCAP == null}
                      onPress={handleSubmit}
                      _text={{
                        fontFamily: "Inclusive Sans, sans-serif",
                      }}
                    >
                      Submit
                    </Button>
                  </VStack>
                )}
              </Formik>
            </VStack>

            <HStack
              space="2"
              mb={{ base: "6", md: "7" }}
              alignItems="center"
              justifyContent="center"
            >
              <Divider
                w="30%"
                _light={{ bg: "coolGray.200" }}
                _dark={{ bg: "coolGray.700" }}
              ></Divider>
              <Text
                fontSize="sm"
                fontWeight="medium"
                _dark={{ color: "coolGray.300" }}
                _light={{ color: "coolGray.400" }}
                fontFamily="Inclusive Sans, sans-serif"
              >
                or
              </Text>
              <Divider
                w="30%"
                _light={{ bg: "coolGray.200" }}
                _dark={{ bg: "coolGray.700" }}
              ></Divider>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
      <HStack space="1" alignItems="center" justifyContent="center">
        <Text
          fontSize="sm"
          color="coolGray.500"
          _dark={{ color: "coolGray.400" }}
          fontFamily="Inclusive Sans, sans-serif"
        >
          Already have an account?
        </Text>

        <Link
          onPress={() => history.push("/")}
          _text={{
            fontFamily: "Inclusive Sans, sans-serif",
            fontSize: "sm",
            fontWeight: "bold",
            textDecoration: "none",
          }}
          _light={{
            _text: {
              color: "primary.900",
            },
          }}
          _dark={{
            _text: {
              color: "primary.700",
            },
          }}
        >
          Sign in
        </Link>
      </HStack>
    </VStack>
  );
}

const SignUp = ({ auth }) => {
  if (auth.uid) return <Redirect to="/Home" />;

  return (
    <GuestLayout>
      <Hidden from="md">
        <VStack
          alignItems="center"
          textAlign={"center"}
          px="4"
          mb="5"
          space="2"
        >
          <Image
            source={{ uri: logo }}
            resizeMode="contain"
            size={200}
            alt="NativeBase logo"
          />
          <VStack mt="-10" space="2">
            <Text fontSize="3xl" fontWeight="bold">
              Welcome
            </Text>
            <Text fontSize="md" fontWeight="normal">
              Sign up to continue
            </Text>
          </VStack>
        </VStack>
      </Hidden>
      <Hidden till="md">
        <Center
          flex="1"
          bg="coolGray.50"
          borderTopLeftRadius={{ base: "0", md: "xl" }}
          borderBottomLeftRadius={{ base: "0", md: "xl" }}
        >
          <Image
            source={{ uri: logo }}
            style={{ fill: "red" }}
            resizeMode="contain"
            size={400}
            alt="NativeBase logo"
          />
        </Center>
      </Hidden>
      <SignUpForm />
    </GuestLayout>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default compose(connect(mapStateToProps))(SignUp);
