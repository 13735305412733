import React, { useState, useRef } from "react";
import { getFirestore } from "redux-firestore";
import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
const ContactSection = () => {
  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [email, setEmail] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [comment, setComment] = useState("");
  const [showFirstNameError, setShowFirstNameError] = useState(false);
  const [showLastNameError, setShowLastNameError] = useState(false);
  const [showCommentError, setShowCommentError] = useState(false);

  const firestore = getFirestore();
  const captchaRef = useRef(null);

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    // Validate email using a simple regex pattern
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsEmailValid(isValidEmail);
    setShowEmailError(!isValidEmail);
  };
  const handleFirstNameChange = (event) => {
    const { value } = event.target;
    const isValidFirstName = /^[a-zA-Z\s]+$/.test(value);
    setFirstName(value);
    setShowFirstNameError(!isValidFirstName);
  };

  const handleLastNameChange = (event) => {
    const { value } = event.target;
    const isValidLastName = /^[a-zA-Z\s]+$/.test(value);
    setLastName(value);
    setShowLastNameError(!isValidLastName);
  };
  const isBlank = /^\s*$/;
  const handleCommentChange = (event) => {
    const { value } = event.target;
    const isValidComment = /^[a-zA-Z0-9\s,'.]+$/g.test(value);
    setComment(value);
    setShowCommentError(!isValidComment && !isBlank.test(value));
  };

  const handleCommentLength = (event) => {
    const { value } = event.target;
    const wordsCount = value.trim().split(/\s+/);
    if (wordsCount > 30) {
      event.preventDefault();
      return;
    }
  };

  const handleCaptchaClick = (value) => {
    setRecaptchaValue(value);
  };
  const handleNoNumber = (event) => {
    if (event.keyCode >= 48 && event.keyCode <= 57) {
      event.preventDefault();
      return;
    }
  };

  function throttle(func, limit) {
    let inThrottle;
    return function () {
      const context = this;
      const args = arguments;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => inThrottle = false, limit);
      }
    };
  }


  const throttledSubmit = async (event) => {
    event.preventDefault();

    if (recaptchaValue == "" || recaptchaValue == null) {
      alert("Please verify you are not a robot.");
    }
    if (
      showCommentError ||
      showFirstNameError ||
      showEmailError ||
      showLastNameError
    ) {
      document
        .querySelector(".div-contact")
        .scrollIntoView({ behavior: "smooth" });
      return;
    } else {
      const formData = new FormData(event.target);
      const firstName = formData.get("First Name");
      const lastName = formData.get("Last Name");
      const emailAddress = formData.get("Email Address");

      if (isBlank.test(firstName)) {
        setShowFirstNameError(true);
        return;
      }
      if (isBlank.test(lastName)) {
        setShowLastNameError(true);
        return;
      }
      if (isBlank.test(emailAddress)) {
        setShowEmailError(true);
        return;
      }

      const commentData = formData.get("Comments");
      // const checkedCheckboxes = document.querySelectorAll('input[type="checkbox"]:checked');
      // const interest = [];

      // checkedCheckboxes.forEach((checkbox) => {
      //   const label = checkbox.parentNode.querySelector(".div-contact-checkbox-label");
      //   if (label && label.textContent.trim() !== "") {
      //     interest.push(label.textContent.trim());
      //   }
      // });
      const interest = [];
      const selectedCheckbox = document.querySelector(
        'input[name="interest"]:checked'
      );
      if (selectedCheckbox) {
        const label = selectedCheckbox.parentNode.querySelector(
          ".div-contact-checkbox-label"
        );
        if (label && label.textContent.trim() !== "") {
          interest.push(label.textContent.trim());
        }
      }

      try {
        await firestore.collection("ContactRequest").add({
          firstName,
          lastName,
          emailAddress,
          interest,
          comment,
        });
        alert("Thanks for your interest. We shall contact you soon !");

        event.target.reset();
        setIsEmailValid(true);
        setShowEmailError(false);
        setShowFirstNameError(false);
        setShowLastNameError(false);
        setShowCommentError(false);
        captchaRef.current.reset();
      } catch (error) {
        console.error("Error adding document: ", error);
        alert("An error occurred while submitting the form. Please try again.");
      }
    }
  };
  const handleSubmit = throttle(throttledSubmit, 1000);
  return (
    <>
      <div className="div-contact" id="div_contact">
        <div className="div-contact-container">
          <div className="div-contact-title">
            Join the waiting list and be the kind of person
            <br />
            who supports accessibility and inclusion for all:
          </div>
          <form onSubmit={handleSubmit}>
            <div className="div-contact-child-1">First Name:</div>
            <div
              className={`div-input-textbox ${showFirstNameError ? "invalid-input" : ""
                }`}
            >
              <input
                name="First Name"
                onBlur={handleFirstNameChange}
                onChange={handleNoNumber}
                title="First Name"
              />
            </div>
            {showFirstNameError && (
              <span className="styled-span-red small">
                Please enter a valid first name.
              </span>
            )}
            <div className="div-contact-child-3">Last Name:</div>
            <div
              className={`div-input-textbox ${showLastNameError ? "invalid-input" : ""
                }`}
            >
              <input
                name="Last Name"
                onBlur={handleLastNameChange}
                onChange={handleNoNumber}
                title="Last Name"
              />
            </div>
            {showLastNameError && (
              <span className="styled-span-red small">
                Please enter a valid last name.
              </span>
            )}

            <div className="div-contact-child-5">Email Address:</div>
            <div
              className={`div-input-textbox ${showEmailError ? "invalid-email" : ""
                }`}
            >
              <input
                name="Email Address"
                className={showEmailError ? "invalid" : ""}
                onBlur={handleEmailChange}
                type="email"
                title="Email Address"
              />
            </div>
            {showEmailError && (
              <span class="styled-span-red small">
                {" "}
                Please enter a valid email address.
              </span>
            )}

            <div className="div-contact-child-8">Which best describes you?</div>
            <div className="div-contact-child-checkbox">
              <input
                type="radio"
                id="venue-checkbox"
                name="interest"
                className="hidden-checkbox"
              />
              <label
                htmlFor="venue-checkbox"
                className="custom-checkbox"
              ></label>
              <div className="div-contact-checkbox-label">Venue/Vendor</div>
            </div>
            <div className="div-contact-child-checkbox">
              <input
                type="radio"
                id="user-checkbox"
                name="interest"
                className="hidden-checkbox"
              />
              <label
                htmlFor="user-checkbox"
                className="custom-checkbox"
              ></label>
              <div className="div-contact-checkbox-label">User</div>
            </div>
            <div className="div-contact-child-checkbox">
              <input
                type="radio"
                id="events-checkbox"
                name="interest"
                className="hidden-checkbox"
              />
              <label
                htmlFor="events-checkbox"
                className="custom-checkbox"
              ></label>
              <div className="div-contact-checkbox-label">Events Organiser</div>
            </div>
            <div className="div-contact-child-checkbox">
              <input
                type="radio"
                id="disability-checkbox"
                name="interest"
                className="hidden-checkbox"
              />
              <label
                htmlFor="disability-checkbox"
                className="custom-checkbox"
              ></label>
              <div className="div-contact-checkbox-label">
                Disability Association
              </div>
            </div>
            <div className="div-contact-child-checkbox">
              <input
                type="radio"
                id="hospitality-checkbox"
                name="interest"
                className="hidden-checkbox"
              />
              <label
                htmlFor="hospitality-checkbox"
                className="custom-checkbox"
              ></label>
              <div className="div-contact-checkbox-label">
                Hospitality Association
              </div>
            </div>
            <div className="div-contact-child-textarea-label">
              Any comments (30-word limit):
            </div>
            <div
              className={`div-contact-child-textarea ${showCommentError ? "invalid-input" : ""
                }`}
            >
              <textarea
                name="Comments"
                onBlur={handleCommentLength}
                onChange={handleCommentChange}
                placeholder="Why are you interested in Piatto?"
                title="Comments"
                maxLength="30"
              />
              {showCommentError && (
                <span className="error-message">
                  No special characters allowed.
                </span>
              )}
            </div>
            <div className="captcha-container">
              <div className="">
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey="6LcjByUqAAAAAH-9xv35Jmu6slfjpN4JILI2nr-1"
                  onChange={(value) => handleCaptchaClick(value)}
                />

              </div>
              <button
                className="div-contact-submit pointer"
                disabled={!recaptchaValue}
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <style jsx>{`
        #div_contact {
          background-color: #fafafa;
          color: #000000;
          border-top: 20px solid #fff;
          width: 90%;
        }
        .div-contact-submit:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          width: 25%;
        }
        .div-contact-container {
          display: flex;
          flex-direction: column;
          align-items: start;
          font-size: 20px;
          font-weight: 400;
          line-height: 70%;
          padding: 5%;
          background-color: #fafafa;
          color: #000000;
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-contact-container {
            margin-top: 40px;
            margin: 0;
          }
        }
        .div-contact-title {
          align-self: stretch;
          font-size: 24px;
          line-height: 117%;
          font-weight: 400;
          font-family: Inclusive Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .div-contact-title {
            max-width: 100%;
          }
        }
        .div-contact-child-1 {
          font-family: Inclusive Sans, sans-serif;
          margin-top: 108px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-contact-child-1 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .div-contact-child-3 {
          font-family: Inclusive Sans, sans-serif;
          margin-top: 45px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-contact-child-3 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .div-contact-child-5 {
          font-family: Inclusive Sans, sans-serif;
          margin-top: 44px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-contact-child-5 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .div-input-textbox {
          align-items: start;
          border-radius: 5px;
          border: 1px solid #e2e1e5;
          background-color: #fff;
          margin-top: 4px;
          width: 300px;
          max-width: 100%;
          justify-content: center;
          color: #d2d2d2;
          white-space: nowrap;
          padding: 5px 60px 5px 15px;
          font: 20px/140% Inclusive Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .div-input-textbox {
            white-space: initial;
            padding-right: 20px;
          }
        }
        .div-contact-child-8 {
          align-self: stretch;
          margin-top: 45px;
          font: 36px/78% Inclusive Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .div-contact-child-8 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-contact-child-checkbox {
          justify-content: center;
          display: flex;
          margin-top: 27px;
          gap: 14px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-contact-child-checkbox {
            white-space: initial;
          }
        }
        .div-contact-checkbox-label {
          font-family: Inclusive Sans, sans-serif;
          flex-grow: 1;
          flex-basis: auto;
        }
        .hidden-checkbox {
          position: absolute;
          opacity: 0;
        }
        .custom-checkbox {
          display: inline-block;
          width: 15px;
          height: 15px;
          border: 1px solid #ccc;
          border-radius: 5px;
          cursor: pointer;
          position: relative;
        }
        /* Show the checkmark inside the custom checkbox */
        .custom-checkbox::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          background-color: transparent;
          border: 2px solid black;
          border-top: none;
          border-right: none;
          transform: translate(-50%, -50%) rotate(-45deg);
          opacity: 0;
        }
        .hidden-checkbox:checked + .custom-checkbox::after {
          opacity: 1; /* Show the checkmark */
        }
        .div-contact-child-textarea-label {
          font-family: Inclusive Sans, sans-serif;
          margin-top: 35px;
          margin-bottom: 5px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-contact-child-textarea-label {
            white-space: initial;
          }
        }
        .div-contact-child-textarea {
          border-radius: 5px;
          border: 1px solid #e2e1e5;
          background-color: #fff;
          width: 300px;
          max-width: 100%;
          color: #d2d2d2;
          padding: 10px 15px 0px;
          font: 20px/28px Inclusive Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .div-contact-child-textarea {
            padding-bottom: 40px;
          }
        }
        .div-contact-submit {
          justify-content: center;
          border-radius: 5px;
          border: 1px solid #5e5bff;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          background-color: #5e5bff;
          margin-top: 22px;
          white-space: nowrap;
          text-align: center;
          padding: 10px 15px;
          font: 14px/28px Inclusive Sans, sans-serif;
          color: #fff;
          width: 25%;
        }
        @media (max-width: 991px) {
          .div-contact-submit {
            white-space: initial;
          }
        }
        .div-contact-submit button {
          background-color: #5e5bff;
          border: none;
          border-radius: 5px;
          color: #fff;
          cursor: pointer;
          font-family: Inclusive Sans, sans-serif;
          font-size: 16px;
          padding: 10px 20px;
          transition: background-color 0.3s ease;
        }

        .div-contact-submit button:hover {
          background-color: #4c4cff;
        }

        .div-contact-child-textarea textarea {
          border: none;
          border-radius: 5px;
          background-color: #fff;
          color: #000;
          font-family: Inclusive Sans, sans-serif;
          font-size: 16px;
          padding: 10px;
          width: 95%;
          resize: vertical;
          min-height: 150px;
        }
        .div-contact-child-textarea textarea:focus {
          border: none;
          border-radius: 5px;
          background-color: #fff;
          color: #000;
          font-family: Inclusive Sans, sans-serif;
          font-size: 16px;
          padding: 10px;
          width: 95%;
          resize: vertical;
          min-height: 150px;
        }
        .invalid input {
          border-color: red; /* Red border for invalid input */
        }
        .error-message {
          color: red;
          margin-top: 5px;
        }
        .small {
          font-size: 14px;
        }
        .captcha-container {
          margin-top: 20px;
        }
        .rc-anchor-alert {
          display: none !important;
        }
      `}</style>
    </>
  );
};

export default ContactSection;
