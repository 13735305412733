import * as React from "react";
import serveDisabledCustomers from "../../../assets/Serve disabled customers.svg";
import knowBeforeTheyArrive from "../../../assets/Know before they arrive.svg";
import visionImpaired from "../../../assets/VisionImpaired.svg";
import cannotAccomodate from "../../../assets/CannotAccomodate.svg";
import notEveryoneCanCommunicate from "../../../assets/NotEveryoneCanCommunicate.svg";
import { useHistory } from "react-router-dom";

import UserAboutSection from "./UserAboutSection";

const VendorAboutSection = () => {
  const history = useHistory();
  const scrollToVendorAbout = () => {
    var position = document.querySelector("#div-vendor-about").scrollTop + 960;

    window.scrollTo({ top: position, behavior: "smooth" });
  };
  const scrollToUserAbout = () => {
    // document
    //   .getElementById("div-user-about")
    //   .scrollIntoView({ behavior: "smooth" });
    const element = document.querySelector("#div-user-about");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Element with id 'div-user-about' not found.");
    }
  };
  return (
    <>
      <div className="div-about">
        <div className="div-about-container">
          <div className="div-title">
            {/* <div className="div-title-1" id="div-main-about">
              Are you a{" "}
              <span
                className="pointer styled-span-red"
                onClick={scrollToVendorAbout}
              >
                Vendor , Venue{" "}
              </span>
              or{" "}
              <span className="pointer styled-span-red" onClick={scrollToUserAbout}>
                {" "}
                User?
              </span>
            </div> */}
            <div className="div-title-2" id="div-vendor-about">
              Piatto is enabling all venues to serve all customers!
            </div>
          </div>

          <div data-aos="fade-right" className="div-15">
            <div className="div-16">
              <div className="column-7">
                <img
                  loading="lazy"
                  src={serveDisabledCustomers}
                  className="img-4"
                />
              </div>
              <div className="column-8" id="div-vendor-about-detail">
                <div className="div-17">
                  Serve disabled customers EVEN if your venue is not accessible!
                  <div className="div-serve-disabled-text-red">
                    No QR necessary!
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* cannot be fade-left, there will be error in layout in mobile effect */}
          <div data-aos="fade-down" className="div-8">
            <div className="div-9">
              <div className="column-6">
                <div className="div-10">
                  Know before they arrive, <br />
                  what their particular requirements are!
                  <div className="div-know-before-text-red">
                    Piatto will tell you!
                  </div>
                </div>
              </div>
              <div className="column-5">
                <img
                  loading="lazy"
                  src={knowBeforeTheyArrive}
                  className="img-2"
                />
              </div>
            </div>
          </div>

          <div className="div-18">
            <div data-aos="fade-down">
              <div className="div-title0">
                <div className="column">
                  <img loading="lazy" src={visionImpaired} className="img-3" />
                </div>
                <div className="column-2">
                  <div className="div-title1">
                    <div className="div-title2">
                      <span className="styled-span">Allow </span>
                      vision impaired, hearing
                      impaired,{" "}
                      <span className="styled-span">as well as customers with <span className="styled-span-red">cognitive impairment</span> to VIEW your menu, place an ORDER
                        and ENJOY your hospitality!</span>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-right" className="div-title4">
              <div className="div-title5">
                <div className="column-3">
                  <div className="div-title6">
                    <div className="div-title7">
                      <div className="div-title8">Can you accommodate?</div>
                    </div>
                    <div className="div-30">
                      If not,{' '}perhaps you wouldn’t mind bringing
                      the order outside?
                    </div>
                  </div>
                </div>
                <div className="column-4">
                  <img
                    loading="lazy"
                    src={cannotAccomodate}
                    className="img-4-vendor"
                  />
                </div>
              </div>
            </div>

            <div data-aos="zoom-in" className="div-15">
              <div className="div-16">
                <div className="div-33">
                  <div className="column-5">
                    <img
                      loading="lazy"
                      src={notEveryoneCanCommunicate}
                      className="img-5"
                    />
                  </div>
                  <div className="column-6">
                    <div className="div-34">
                      <div className="div-35">
                        Not everyone can communicate
                        their desires to the cashier!
                        Order via Piatto, no talking required!
                      </div>
                      <div className="div-36">Let technology handle it! </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className="div-37">
              If your venue is accessible, you have a ramp, you have braille
              on your menus, you have audio narrated menus, you have a
              quiet spot for those requiring sensory minimal locations,&nbsp;
              <span className="styled-span-red">
                those facts will be made obvious on Piatto!
              </span>{" "} <br />
              If not, at least disabled persons can still enjoy your hospitality with Piatto!
              <br />
              <div className="div-37">
                <span className="styled-span-red">
                  Click the{" "}
                  <span
                    onClick={() => history.push("/SignUp")}
                    className="span-green pointer"
                  >
                    GREEN
                  </span>{" "}
                  button above to make your hospitality venue , cafe ,
                  restaurant , bar , pop-up or food truck more accessible
                  OVERNIGHT!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserAboutSection />
      <style jsx>{`
        .div-title {
          display: flex;
          width: 90%;
          flex-direction: column;
          font-size: 32px;
          font-weight: 400;
          padding: 0 20px;
          align-items: center;
        }
        @media (max-width: 991px) {
          .div-title {
            max-width: 100%;
          }
        }
        .div-3 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 811px;
          justify-content: space-between;
          gap: 20px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-3 {
            max-width: 100%;
            flex-wrap: wrap;
            white-space: initial;
          }
        }

        .div-title-1 {
          font-family: Inclusive Sans, sans-serif;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .div-title-1 {
            white-space: initial;
          }
        }
        .div-6 {
          font-family: Inclusive Sans, sans-serif;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
        }
        .div-7 {
          font-family: Inclusive Sans, sans-serif;
        }
        .div-8 {
          font-family: Inclusive Sans, sans-serif;
          flex-grow: 1;
          margin: 85px auto 0;
        }
        .div-9 {
          font-family: Inclusive Sans, sans-serif;
          flex-grow: 1;
          flex-basis: auto;
          justify-content: flex-end;
          margin: auto 0;
        }
        .div-title-2 {
          display: flex;
          align-item: center;
          justify-content: center;
          font-family: Inclusive Sans, sans-serif;
          // margin-top: 126px;
          // width: 100%;
          margin-top: 50px;
        }
        @media (max-width: 991px) {
          .div-title-2 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-serve-disabled {
          z-index: 10;
          display: flex;
          margin-top: 68px;
          width: 90%;
          flex-direction: column;
          align-items: end;
          font-size: 32px;
          padding: 0 15px 0 77px;
        }
        @media (max-width: 991px) {
          .div-serve-disabled {
            max-width: 100%;
            padding-left: 20px;
            margin-top: 40px;
          }
        }
        .img {
          aspect-ratio: 1.52;
          object-fit: auto;
          object-position: center;
          align-self: start;
          max-width: 100%;
        }
        .div-serve-disabled-text {
          z-index: 10;
          display: flex;
          width: 400px;
          max-width: 100%;
          flex-direction: column;
          margin: -202px 109px 0 50px;
        }
        @media (max-width: 991px) {
          .div-serve-disabled-text {
            margin: -200px 10px 0 50px;
          }
        }
        .div-serve-disabled-text-value {
          color: #fff;
          font-family: Inclusive Sans, sans-serif;
          font-weight: 400;
        }
        @media (max-width: 991px) {
          .div-serve-disabled-text-value {
            max-width: 100%;
          }
        }
        .div-serve-disabled-text-red {
          color: #a40204;
          font-family: Inclusive Sans, sans-serif;
          font-weight: 700;
          margin-top: 37px;
        }
        .img-2 {
          aspect-ratio: 1.59;
          object-fit: auto;
          object-position: center;
          margin-top: 27px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .div-serve-disabled-text-red {
            max-width: 100%;
          }
          .img-2 {
            width: 100%;
          }
        }
       
        .div-know-before {
          display: flex;
          width: 621px;
          max-width: 100%;
          flex-direction: column;
          font-size: 32px;
          margin: -145px 0 0 34px;
        }
        .div-know-before-text {
          color: #fff;
          font-family: Inclusive Sans, sans-serif;
          font-weight: 400;
        }
        @media (max-width: 991px) {
          .div-know-before-text {
            max-width: 100%;
          }
        }
        .div-know-before-text-red {
          color: #a40204;
          font-family: Inclusive Sans, sans-serif;
          font-weight: 700;
          margin-top: 30px;
        }
        @media (max-width: 991px) {
          .div-know-before-text-red {
            max-width: 100%;
          }
        }
        .div-18 {
          display: flex;
          margin-top: 60px;
          width: 100%;
          flex-direction: column;
          justify-content: flex-end;
        }
        @media (max-width: 991px) {
          .div-18 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-19 {
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-19 {
            max-width: 100%;
          }
        }
        .div-title0 {
          gap: 20px;
          display: flex;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .div-title0 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .img-3 {
          aspect-ratio: 1.75;
          object-fit: auto;
          object-position: center;
          width: 100%;
          flex-grow: 1;
          width: 500px;
        }
        @media (max-width: 991px) {
          .img-3 {
            margin-top: 40px;
            width: 100%;
          }
        }

        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .div-title1 {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          font-size: 32px;
          margin: auto 0;
          padding: 0 30px;
        }
        @media (max-width: 991px) {
          .div-title1 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-title2 {
          color: #a40204;
          font-family: Inclusive Sans, sans-serif;
          font-weight: 700;
        }
        @media (max-width: 991px) {
          .div-title2 {
            max-width: 100%;
          }
        }
        .div-title3 {
          font-family: Inclusive Sans, sans-serif;
          font-weight: 400;
          margin-top: 8px;
        }
        @media (max-width: 991px) {
          .div-title3 {
            max-width: 100%;
          }
        }
        .div-title4 {
          margin: 120px 0 0 0;
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-title4 {
            max-width: 100%;
          }
        }
        .div-title5 {
          gap: 20px;
          display: flex;
          justify-content: flex-end;
        }
        @media (max-width: 991px) {
          .div-title5 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 65%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .div-title6 {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          font-size: 32px;
          margin: auto 0;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .div-title6 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-title7 {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
        @media (max-width: 991px) {
          .div-title7 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-title8 {
          font-family: Inclusive Sans, sans-serif;
          font-weight: 400;
          flex-basis: auto;
        }
        .div-title9 {
          color: #a40204;
          font-family: Inclusive Sans, sans-serif;
          font-weight: 700;
          flex-grow: 1;
          flex-basis: auto;
        }
        .div-30 {
          color: #a40204;
          font-family: Inclusive Sans, sans-serif;
          font-weight: 700;
          margin-top: 10px;
        }
        @media (max-width: 991px) {
          .div-30 {
            max-width: 100%;
          }
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 35%;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .img-4-vendor {
          aspect-ratio: 1.82;
          object-fit: auto;
          object-position: center;
          flex-grow: 1;
          width: 500px;
        }
        @media (max-width: 991px) {
          .img-4-vendor {
            margin-top: 40px;
            width: 100%;
          }
        }
        .div-31 {
          display: flex;
          margin-top: 6px;
          width: 100%;
          flex-direction: column;
          padding: 0 80px 0 15px;
        }
        @media (max-width: 991px) {
          .div-31 {
            max-width: 100%;
            padding-right: 20px;
          }
        }
        .div-32 {
          margin: 0 14px 0 16px;
        }
        @media (max-width: 991px) {
          .div-32 {
            max-width: 100%;
            margin-right: 10px;
          }
        }
        .div-33 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-33 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 37%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .img-5 {
          aspect-ratio: 1.54;
          object-fit: auto;
          object-position: center;
          width: 500px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-5 {
            margin-top: 40px;
            width: 100%;
          }
        }
        .column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
        }
        @media (max-width: 991px) {
          .column-6 {
            width: 100%;
          }
        }
        .div-34 {
          display: flex;
          margin-top: 48px;
          flex-direction: column;
          font-size: 32px;
        }
        @media (max-width: 991px) {
          .div-34 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-35 {
          font-family: Inclusive Sans, sans-serif;
          font-weight: 400;
        }
        @media (max-width: 991px) {
          .div-35 {
            max-width: 100%;
          }
        }
        .div-36 {
          color: #a40204;
          font-family: Inclusive Sans, sans-serif;
          font-weight: 700;
          margin-top: 18px;
        }
        @media (max-width: 991px) {
          .div-36 {
            max-width: 100%;
          }
        }
        .div-37 {
          margin: 100px 14px 0 0;
          font: 400 32px Inclusive Sans, sans-serif,
            sans-serif;
        }
        .div-about-container {
          display: flex;
          align-item: center;
          flex-direction: column;
          background-color: #f5f5f7;
          width: 71%;
        }
        @media (max-width: 991px) {
          .div-about-container {
            width: 100%;
          }
          .div-37 {
            max-width: 100%;
            margin: 40px 10px 0 0;
          }
        }
        .styled-span {
          font-weight: 400;
          color: #0d0d0d;
        }
        .styled-span-red {
          font-weight: 400;
          color: #a40204;
          font: 700 32px Inclusive Sans, sans-serif,
            sans-serif;
        }
        .styled-span-black {
          font-weight: 400;
          font: 700 32px Inclusive Sans, sans-serif,
            sans-serif;
        }
        .div-about {
          display: flex;
          justify-content: center;
          background-color: #f5f5f7;
          padding: 5%;
          border-top: 20px solid #fff;
          width: 90%;
        }
        
        .img {
          aspect-ratio: 1.82;
          object-fit: auto;
          object-position: center;
          align-self: stretch;
          max-width: 100%;
          width: 317px;
        }
        .span-green {
          color: #a8ff35;
        }
      `}</style>
    </>
  );
};

export default VendorAboutSection;
