import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { NativeBaseProvider, extendTheme } from "native-base";
import reportWebVitals from "./reportWebVitals";
import "./assets/custom.css";
import fontsCSS from "@native-base/icons/FontsCSS/index.ts";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { persistor, rrfProps, store } from "./Redux/store";
import "./closestpolyfill";
import * as Sentry from "@sentry/react";

const style = document.createElement("style");
style.type = "text/css";
style.appendChild(document.createTextNode(fontsCSS));
document.head.appendChild(style);
if (process.env.NODE_ENV !== "development") console.log = () => {};

const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
});

Sentry.init({
  dsn: "https://0da9346b2df50ef77213c8e576d05c04@o4507444784332800.ingest.us.sentry.io/4507444791934976",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <NativeBaseProvider theme={theme}>
          
          <App />

        </NativeBaseProvider>
      </ReactReduxFirebaseProvider>
    </PersistGate>

  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
